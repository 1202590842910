import { useState, useEffect } from 'react'
import { DailyBalanceObject } from '../components/CompactTable/CompactTable'
import { getPatientView } from '../core/api/get-patient-view'
import { Patient } from '../core/entities/patient.entity'

export function usePatientData(patient: Patient | null, type: string) {
  const [patientViewData, setPatientViewData] =
    useState<DailyBalanceObject | null>(null)
  const [patientViewError, setPatientViewError] = useState<Error | null>(null)

  useEffect(() => {
    if (!patient) return

    const controller = new AbortController()

    getPatientView(patient.patientId, type, controller.signal)
      .then((response) => response.data)
      .then(setPatientViewData)
      .catch(setPatientViewError)

    return () => {
      controller.abort()
    }
  }, [patient, type])

  return {
    patientViewData,
    patientViewError
  }
}
