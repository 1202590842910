import styled, { CSSProperties } from 'styled-components'
import dayjs from '../../core/dayjs/dayjs'
import { DailyBalanceData } from '../CompactTable/CompactTable'
import { DiscardUnit } from '../DiscardUnit/DIscardUnit'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { ReactNode, useState } from 'react'

interface TimeData {
  data: string | null
  yesterday: boolean | null
}
interface TimeProps {
  data: Array<TimeData>
  name: string
}

interface ValueObject {
  value: string
  discarded: boolean
}
interface ValueComponentData {
  data: Array<ValueObject>
  name: string
  unit: boolean
  style?: CSSProperties
  headerChildren?: ReactNode
}

interface ValuesData {
  data: Array<ValueObject>
  name: string
}

export interface DailyTableDay {
  weight: number
  localTime: string
  time: string
  type: string
  tray: number
  comment: string
  yesterday: boolean
  displayWeight: string
}

export interface DailyTableObject {
  type: string
  name: string
  day: string
  events: Array<DailyTableDay>
  balance: number
  inputTotal: string
  outputTotal: string
}

const DailyTableWrapper = styled.div`
  width: 100%;
  position: relative;
`
const ToolTip = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--border-color);
  background-color: var(--white-color);
  opacity: 0.9;
  z-index: 100;
  position: absolute;
  width: 15rem;
  text-align: center;
  right: 0;
  transform: translateX(7rem) translateY(-2.5rem);
`
const ToolTipText = styled.div`
  font-size: 0.75rem;
  color: var(--darkFont-color);
  font-family: interMedium;
  opacity: 1;
`
const IconLogo = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 0.875rem;
  color: var(--greyFont-color);
  margin-right: 1rem;
  &:hover {
    color: var(--brand-red-color);
  }
`
const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border-top: 0.0625rem solid var(--border-color);
  border-right: 0.0625rem solid var(--border-color);
  border-left: 0.0625rem solid var(--border-color);
`
const Table = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--darkfont-color);
  position: relative;
  border-radius: 0.5rem;
`
const Box = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-bottom: 0.0625rem solid var(--border-color);
  justify-content: flex-start;
`
export const BoxValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-right: 0rem;
  gap: 0.5rem;
  justify-content: center;
`
const Value = styled.div`
  font-weight: 600;
  white-space: nowrap;
`
export const Unit = styled.div`
  color: var(--greyFont-color);
  font-weight: 600;
  font-size: 0.75rem;
`
const HeaderBox = styled.div`
  height: 2.9375rem;
  display: flex;
  background-color: var(--headerbackground-color);
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  gap: 0.5rem;
  border-bottom: 0.0625rem solid var(--border-color);
`
const HeaderText = styled.div`
  white-space: nowrap;
  margin-left: 1rem;
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const ColumnResize = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
`
const MeasurementColumn = styled.div`
  width: 9.25rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
`
const TimeColumn = styled(MeasurementColumn)`
  width: 9.25rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
`
const GetDataNodesWithUnit = (
  data: Array<ValueObject>,
  name: string,
  style?: CSSProperties
) => {
  const { t } = useTranslation()
  const allData = data.map((value, index) => (
    <Box
      key={name + value.value}
      data-testid={name + index}
      id={name + index}
      style={style}
    >
      <BoxValues style={style}>
        <Value>{t(value.value)}</Value>
        <Unit>g</Unit>
      </BoxValues>
    </Box>
  ))
  return allData
}
const GetDataNodesWithoutUnit = (
  data: Array<ValueObject>,
  name: string,
  style?: CSSProperties
) => {
  const { t } = useTranslation()
  const allData = data.map((value, index) => (
    <Box
      key={name + value.value}
      data-testid={name + index}
      id={name + index}
      style={style}
    >
      <BoxValues>
        <Value>{value.value ? t(value.value) : '-'}</Value>
        {value.discarded && name === 'Type' ? <DiscardUnit /> : <></>}
      </BoxValues>
    </Box>
  ))
  return allData
}
const GetTimeNodes = ({ data, name }: TimeProps) => {
  const { t } = useTranslation()
  const allData = data.map((value, index) => (
    <Box key={name + data} data-testid={name + index} id={name + index}>
      <BoxValues>
        <Value>
          {value.yesterday
            ? dayjs(value.data).format('HH:mm') + ` - ${t('yesterday')}`
            : dayjs(value.data).format('HH:mm')}
        </Value>
      </BoxValues>
    </Box>
  ))
  return allData
}

const ValueComponent = ({
  data,
  name,
  unit,
  style,
  headerChildren
}: ValueComponentData) => {
  const allData = unit
    ? GetDataNodesWithUnit(data, name, style)
    : GetDataNodesWithoutUnit(data, name, style)

  return (
    <ColumnResize>
      <HeaderBox style={style}>
        <HeaderText style={headerChildren ? {} : style}>{name}</HeaderText>
        {headerChildren}
      </HeaderBox>
      {allData}
    </ColumnResize>
  )
}
const MeasurementComponent = ({ data, name }: ValuesData) => {
  const allData = GetDataNodesWithoutUnit(data, name)
  return (
    <MeasurementColumn>
      <HeaderBox>
        <HeaderText>{name}</HeaderText>
      </HeaderBox>
      {allData}
    </MeasurementColumn>
  )
}
const TimeComponent = ({ data, name }: TimeProps) => {
  const allData = GetTimeNodes({ data, name })
  return (
    <TimeColumn>
      <HeaderBox>
        <HeaderText>{name}</HeaderText>
      </HeaderBox>
      {allData}
    </TimeColumn>
  )
}
const TableComponent = ({ data, is24, setShowTooltip }: DailyBalanceData) => {
  const { t } = useTranslation()
  const measurementArray = data.events.map((_value, index) => {
    return {
      value: (data.events.length - index).toString(),
      discarded: false
    }
  })
  const timeArray = data.events.map((value) => {
    return { data: value.localTime, yesterday: value.yesterday }
  })
  const typeArray = data.events.map((value) => {
    return value.type.includes('input')
      ? {
          value: value.type.includes('oral')
            ? 'Input - Oral'
            : `Input - ${t('Intravenous')}`,
          discarded: value.type.includes('discard')
        }
      : {
          value:
            value.type.charAt(0).toUpperCase() +
            value.type.substring(1, 6) +
            ' - ' +
            t(
              value.type.charAt(6).toUpperCase() +
                value.type.substring(7, value.type.length)
            ),
          discarded: value.type.includes('discard')
        }
  })

  const commentArray = data.events.map((value) => {
    return { value: value.comment, discarded: value.type.includes('discard') }
  })
  const weightArray = data.events.map((value) => {
    return {
      value: value.displayWeight
        ? value.displayWeight.toString().replace(/-/g, '- ')
        : '0',
      discarded: value.type.includes('discard')
    }
  })
  return (
    <Table data-testid="DailyTable" style={{ position: 'relative' }}>
      <MeasurementComponent
        name={`${t('Measurement')}`}
        data={measurementArray}
      />
      <TimeComponent data={timeArray} name={`${t('Time')}`} />
      <ColumnWrapper>
        <ValueComponent name={`${t('Type')}`} data={typeArray} unit={false} />
        <ValueComponent
          name={`${t('Comment')}`}
          data={commentArray}
          unit={false}
        />
        <ValueComponent
          name={`${t('Weight')}`}
          data={weightArray}
          unit={true}
          style={{ justifyContent: 'flex-end', marginRight: '1rem' }}
          headerChildren={
            <IconLogo
              icon={faInfoCircle}
              onMouseEnter={() =>
                setShowTooltip ? setShowTooltip(true) : null
              }
              onMouseLeave={() =>
                setShowTooltip ? setShowTooltip(false) : null
              }
              data-testid="tooltipLogo"
            />
          }
        />
      </ColumnWrapper>
    </Table>
  )
}

export const DailyTable = ({ data, is24 }: DailyBalanceData) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <DailyTableWrapper>
      {showTooltip && (
        <ToolTip>
          <ToolTipText>
            {t(
              'Container weight has already been subtracted from all values in this column'
            )}
          </ToolTipText>
        </ToolTip>
      )}
      <TableWrapper>
        <TableComponent
          key={'dailytable'}
          data={data}
          is24={is24}
          setShowTooltip={setShowTooltip}
        />
      </TableWrapper>
    </DailyTableWrapper>
  )
}
