import { useTranslation } from 'react-i18next'
import { setLanguage } from '../../core/i18n'

export const ChangeLanguage = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation()

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setLanguage(value)
  }

  return (
    <select
      className={className}
      onChange={(e) => changeLanguage(e.target.value)}
      value={i18n.language}
      data-testid="selectLanguage"
    >
      <option data-testid="languageOption" value="da">
        Dansk
      </option>
      <option data-testid="languageOption" value="en">
        English
      </option>
    </select>
  )
}
