import { CompactTable } from '../../components/CompactTable/CompactTable'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePatientData } from '../../hooks/get-patient-data'

export const Compact = () => {
  const { patient } = usePatientContext()
  const { patientViewData } = usePatientData(patient, 'dailybalance')

  if (patientViewData) {
    return <CompactTable data={patientViewData} />
  } else {
    return <Spinner spinnersize={spinnerSize.large} />
  }
}
