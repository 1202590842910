import styled from 'styled-components'

const ClinicCardComponent = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--lightGrey-color);
  opacity: 0.97;
  border: 0.0625rem solid var(--border-color);
  border-radius: 1rem;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
`
const TitleWrapperComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.1875rem;
`
const TitleComponent = styled.div`
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--greyFont-color);
`
const CPRComponent = styled.div`
  font-family: interMedium;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  color: var(--darkFont-color);
  cursor: pointer;
  &:hover {
    color: var(--brand-red-color);
  }
`

type ClinicCardProps = {
  CPR: string
}
export const ClinicCard = ({ CPR }: ClinicCardProps) => {
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(CPR)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ClinicCardComponent data-testid="clinicCard">
      <TitleWrapperComponent>
        <TitleComponent>Patient ID</TitleComponent>
        <CPRComponent
          data-testid="clinicCardClipboard"
          onClick={handleCopyToClipboard}
        >
          {CPR}
        </CPRComponent>
      </TitleWrapperComponent>
    </ClinicCardComponent>
  )
}
