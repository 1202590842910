import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DiscardUnitComponent = styled.div`
  padding: 0.125rem 0.0625rem 0.125rem 0.125rem;
  border: 0.125rem solid var(--greyFont-color);
  border-radius: 0.25rem;
`
const DiscardUnitText = styled.div`
  font-family: interMedium;
  font-weight: 800;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: var(--greyFont-color);
`

export const DiscardUnit = () => {
  const { t } = useTranslation()
  return (
    <DiscardUnitComponent>
      <DiscardUnitText>{t('discarded')}</DiscardUnitText>
    </DiscardUnitComponent>
  )
}
