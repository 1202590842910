import * as React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Navigate } from 'react-router-dom'

export function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { keycloak } = useKeycloak()

  if (keycloak?.authenticated) {
    return <>{children}</>
  }

  return <Navigate to={{ pathname: '/' }} />
}
