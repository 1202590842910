import { Navigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { ChangeLanguage } from '../../components/ChangeLanguage/ChangeLanguage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Title, titleEnum } from '../../components/Title/Title'
import { useSession } from '../../core/session/use-session'

const Screen = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
`

const LanguageSelector = styled(ChangeLanguage)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border: 0;
  border-radius: 0.5rem;
  color: var(--white-color);
  background: rgba(255, 255, 255, 0.25);
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`

const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  border-radius: 1rem;
  width: 27.5rem;
  cursor: pointer;
  background-color: var(--lightGrey-color);
  border: 0.0625rem solid var(--border-color);
`
const LoginText = styled.div`
  font-family: interMedium;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--darkFont-color);
`
const Arrow = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 1.25rem;
  color: var(--darkFont-color);
`
export const Login = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { login, isAuthenticated } = useSession()

  const currentLocationState: any = location.state || {
    from: { pathname: '/portal' }
  }

  if (isAuthenticated) {
    return <Navigate to={currentLocationState?.from as string} />
  }
  return (
    <Screen>
      <LanguageSelector />
      <Container>
        <Title size={titleEnum.medium} />
        <LoginButton onClick={login} data-testid="login-button">
          <LoginText>{t('Login')}</LoginText>
          <Arrow icon={faChevronRight} />
        </LoginButton>
      </Container>
    </Screen>
  )
}
