import { Navigate, Route, Routes } from 'react-router-dom'
import Modal from 'react-modal'
import { Login } from './containers/Login/Login'
import { PrivateRoute } from './core/keycloak/PrivateRoute'
import './App.css'
import dayjs from './core/dayjs/dayjs'
import i18n from './core/i18n'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { PatientDetails } from './containers/PatientDetails/PatientDetails'
import { PortalLayout } from './components/PortalLayout//PortalLayout'
import { PatientSearch } from './containers/PatientSearch/PatientSearch'
import { Detailed } from './containers/Detailed/Detailed'
import { Compact } from './containers/Compact/Compact'
import { OneDay } from './containers/OneDay/OneDay'
import { CurrentPeriod } from './containers/CurrentPeriod/CurrentPeriod'
dayjs.locale(i18n.language)

const modalRoot = document.createElement('div')
modalRoot.setAttribute('id', 'modal-root')
document.body.appendChild(modalRoot)
Modal.setAppElement(modalRoot)

function App() {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/portal"
          element={
            <PrivateRoute>
              <PortalLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to="patients/search" />} />
          <Route path="dashboard" element={<div />} />
          <Route path="patients/search" element={<PatientSearch />} />
          <Route path="patients/:patientId" element={<PatientDetails />}>
            <Route path="detailed_history" element={<Detailed />} />
            <Route path="compact_history" element={<Compact />} />
            <Route path="current_period" element={<CurrentPeriod />} />
            <Route path="24_hours" element={<OneDay />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <main>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </ErrorBoundary>
  )
}

export default App
