import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import {
  DailyBalanceDataId,
  DailyBalanceEvent
} from '../CompactTable/CompactTable'
import { DailyTable } from '../DailyTable/DailyTable'
import { InputOutputTables } from '../InputOutputTables/InputOutputTables'
dayjs.locale('en')

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  gap: 1rem;
  border-radius: 1.5rem;
  border: 0.0625rem solid var(--border-color);
  padding: 1rem;
  background-color: var(--white-color);
  box-shadow: 0rem 0.5rem 1rem var(--boxshadow-color);
  scroll-margin-top: 10rem;
`
export interface FinishedProps {
  isfinished: boolean
}
export enum TitleType {
  is24,
  istoday,
  iscurrentperiod,
  default
}
interface TitleProps {
  balance: number | null
  date: string
  starttime: string
  endtime: string
  type: TitleType
}

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin-left: 0.5rem;
  text-transform: capitalize;
`
const Date = styled.div`
  font-family: interMedium;
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
`
const DateIncomplete = styled(Date)`
  color: var(--brand-orange-color);
  text-transform: Uppercase;
`
const SubDateWrapper = styled.div`
  font-family: interMedium;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  margin-left: 0.5rem;
  font-weight: 600;
`
const SubDateDarkColor = styled.div`
  color: var(--darkFont-color);
`
const SubDateGreyColor = styled.div`
  color: var(--greyFont-color);
`
const Line = styled.div`
  background-color: var(--border-color);
  height: 0.0625rem;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
`
const Balance = styled.div<FinishedProps>`
  font-family: interMedium;
  font-size: 1.25rem;
  color: ${(props) =>
    props.isfinished ? 'var(--white-color)' : 'var(--darkFont-color)'};
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
  background-color: ${(props) =>
    props.isfinished ? 'var(--black-bg-90-color)' : 'none'};
  border-radius: 0.5rem;
  border: ${(props) =>
    props.isfinished ? 'none' : '0.0625rem solid var(--border-color)'};
`
const BalanceUnit = styled.div`
  color: var(--greyFont-color);
  font-size: 1.25rem;
  font-family: interMedium;
  font-weight: 600;
`
const TodaysDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`
export const dotanimation = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0.1}
  `
const TodayDot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.625rem;
  background-color: var(--brand-orange-color);
  animation-name: ${dotanimation};
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`
const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const getTitletype = (is24: boolean, data: DailyBalanceEvent) => {
  if (is24) return TitleType.is24
  else if (data.currentDay) return TitleType.istoday
  else return TitleType.default
}
const RenderTitle = (type: TitleType, date: string) => {
  const { t } = useTranslation()
  switch (type) {
    case TitleType.iscurrentperiod:
      return (
        <TodaysDateWrapper>
          <Date>{t('Current Period, Acc.')}</Date>
        </TodaysDateWrapper>
      )
    case TitleType.is24:
      return (
        <TodaysDateWrapper>
          <Date>{t('Last 24 Hours')}</Date>
        </TodaysDateWrapper>
      )
    case TitleType.istoday:
      return (
        <TodaysDateWrapper>
          <Date>
            {dayjs(date).format('dddd') +
              ' ' +
              dayjs(date).format('DD-MM-YYYY')}
          </Date>
          <DateIncomplete>{t('Incomplete')}</DateIncomplete>
        </TodaysDateWrapper>
      )
    default:
      return (
        <Date>
          {dayjs(date).format('dddd') + ' ' + dayjs(date).format('DD-MM-YYYY')}
        </Date>
      )
  }
}
export const DetailedTitle = ({
  balance,
  date,
  starttime,
  endtime,
  type
}: TitleProps) => {
  const { t } = useTranslation()
  return (
    <Header>
      <HeaderTop>
        <DateWrapper>{RenderTitle(type, date)}</DateWrapper>
        <Line></Line>
        <Balance
          isfinished={type === TitleType.is24 || type !== TitleType.istoday}
        >
          {t('Fluid Balance')} &nbsp;{' '}
          {balance !== null ? (
            <span>{balance.toString().replace(/-/g, '- ')}</span>
          ) : (
            <span style={{ fontStyle: 'italic', fontSize: '1rem' }}>
              {t('No Data')}
            </span>
          )}
          {balance !== null && <BalanceUnit>&nbsp;g</BalanceUnit>}
        </Balance>
      </HeaderTop>
      <SubDateWrapper>
        {type === TitleType.istoday ? <TodayDot></TodayDot> : <></>}
        {balance !== null && (
          <SubDateGreyColor>{t('From')}&nbsp;</SubDateGreyColor>
        )}
        <SubDateDarkColor>
          {starttime.split(':').slice(0, 2).join(':')}
          &nbsp;
          {type === TitleType.is24 ? `${t('yesterday')}\xa0` : ''}
        </SubDateDarkColor>
        {balance !== null && (
          <SubDateGreyColor>{t('to')}&nbsp;</SubDateGreyColor>
        )}
        <SubDateDarkColor>
          {balance !== null ? (
            endtime.split(':').slice(0, 2).join(':')
          ) : (
            <span style={{ fontStyle: 'italic' }}> {endtime}</span>
          )}
          &nbsp;
          {type === TitleType.is24 ? `${t('today')}\xa0` : ''}
        </SubDateDarkColor>
      </SubDateWrapper>
    </Header>
  )
}

export const CombinedTables = ({ data, is24, id }: DailyBalanceDataId) => {
  return (
    <TablesWrapper data-testid={is24 ? '24hourtable' : id} id={id}>
      <DetailedTitle
        balance={data.balance}
        date={data.day}
        starttime={data.startTime}
        endtime={data.endTime}
        type={getTitletype(is24, data)}
      />
      <InputOutputTables data={data} is24={is24} />
      <DailyTable data={data} is24={is24} />
    </TablesWrapper>
  )
}
