import ReactModal from 'react-modal'
import './modal.css'

export const Modal = ({ children, ...props }: ReactModal.Props) => {
  return (
    <ReactModal className="modal" overlayClassName="modal-overlay" {...props}>
      {children}
    </ReactModal>
  )
}
