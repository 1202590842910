import { ReactNode } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HeaderButtonComponent = styled.button`
  background-color: var(--patientSearchBG-color);
  color: var(--darkFont-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: var(--patientSearchBG-color);
    color: var(--darkFont-color);
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const Text = styled.div`
  font-family: interMedium;
  font-weight: 500;
  font-size: 0.875rem;
`
const PreText = styled(Text)`
  color: var(--greyFont-color);
`
const Logo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 400;
  color: var(--brand-red-color);
`
type ButtonProps = {
  icon: any
  children: ReactNode
  onClick?: () => void
}

export const HeaderButton = ({ icon, children, ...props }: ButtonProps) => {
  return (
    <HeaderButtonComponent {...props}>
      <TextWrapper>
        <PreText> Kliniker </PreText>
        <Text>{children}</Text>
      </TextWrapper>
      <Logo icon={icon}></Logo>
    </HeaderButtonComponent>
  )
}
