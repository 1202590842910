import styled from 'styled-components'
import { CombinedTables } from '../../components/CombinedTables/CombinedTables'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePatientData } from '../../hooks/get-patient-data'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  padding-bottom: 3rem;
`

export const anchorClick = (id: string) => {
  document.getElementById('detailed_history_nav')?.click()
  setTimeout(() => {
    const anchor = document.createElement('a')
    anchor.href = '#detailedday' + id
    anchor.click()
    anchor.remove()
  }, 300)
}

export const Detailed = () => {
  const { patient } = usePatientContext()
  const { patientViewData } = usePatientData(patient, 'dailybalance')
  if (patientViewData) {
    const tables = patientViewData.balances.map((item, index) => {
      return (
        <CombinedTables
          id={'detailedday' + index}
          key={'day' + item.day}
          data={item}
          is24={false}
        />
      )
    })
    return <Wrapper data-testid="detailedwrapper">{tables}</Wrapper>
  } else {
    return <Spinner spinnersize={spinnerSize.large} />
  }
}
