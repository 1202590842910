import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import { FlexRowWrapper } from '../FlexWrappers/FlexRowWrapper'

const PatientsViewNavigationComponent = styled(FlexRowWrapper)`
  border-radius: 0.75rem;
  gap: 0.25rem;
  padding: 0.25rem;
  border: 0.0625rem solid #ecebec;
  justify-content: center;
  width: min-content;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white-color);
  z-index: 2;
`
const Blur = styled.div`
  position: fixed;
  width: 100vw;
  margin-top: 6rem;
  height: 3.8125rem;
  top: 0;
  left: 0;
  background-color: var(--transparentWhite80-color);
  backdrop-filter: blur(0.25rem);
  z-index: 2;
`
const StyledPatientsViewNavigationComponent = styled(
  PatientsViewNavigationComponent
)`
  margin-top: 0.625rem;
`

interface SlideProps {
  prevpos: number
  pos: number
}

type PatientsViewButtonProps = {
  children: ReactNode
  to: string
  notification: boolean
  index: number
  setActiveState: Dispatch<
    SetStateAction<{
      prevpos: number
      pos: number
    }>
  >
  activeState: SlideProps
}
const SlidingBackground = styled.div<SlideProps>`
  height: calc(100% - 0.5rem);
  width: 10rem;
  background-color: var(--patientSearchBG-color);
  border-radius: 0.5rem;
  position: absolute;
  animation-name: ${(props) =>
    slideanimation({ prevpos: props.prevpos, pos: props.pos })};
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-direction: forward;
  animation-fill-mode: forwards;
`
const slideanimation = ({ prevpos, pos }: SlideProps) => keyframes`
 0% { left: calc(${prevpos} * 10rem + 0.25rem + (${prevpos} * 0.25rem));}
 100% { left: calc(${pos} * 10rem + 0.25rem + (${pos} * 0.25rem));}
`
const PatientsViewButtonComponent = styled(NavLink)`
  padding: 0.75rem 1.5rem;
  width: 7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  isolation: isolate;
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border: none;
  color: var(--patientsViewButtonNotActive-color);
  text-decoration: none;
  white-space: nowrap;
  &.active {
    color: var(--darkFont-color);
  }
  &:hover {
    color: var(--darkFont-color);
  }
`
interface NotificationLabelProps {
  notification: boolean
}
const NotificationLabel = styled.div<NotificationLabelProps>`
  border-radius: 50%;
  border: none;
  background-color: var(--notification-color);
  width: 0.625rem;
  height: 0.625rem;
  display: ${(props) => (props.notification ? 'block' : 'none')};
`

const PatientsViewButton = ({
  children,
  to,
  notification,
  index,
  setActiveState,
  activeState
}: PatientsViewButtonProps) => {
  return (
    <PatientsViewButtonComponent
      id={to + '_nav'}
      to={to}
      onClick={() => {
        const newState = { prevpos: activeState.pos, pos: index }
        setActiveState(newState)
      }}
    >
      <PatientsViewButtonText>
        <NotificationLabel notification={notification}> </NotificationLabel>
        {children}
      </PatientsViewButtonText>
    </PatientsViewButtonComponent>
  )
}

const PatientsViewButtonText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor-pointer: none;
`
type PatientsViewNavigationProps = {
  pages: Array<{ label: string; to: string; notification: boolean }>
}

export const PatientsViewNavigation = ({
  pages
}: PatientsViewNavigationProps) => {
  const [activeState, setActiveState] = useState({ prevpos: 0, pos: 0 })
  const pagesToRender = pages.map((item, index) => (
    <PatientsViewButton
      key={item.to}
      to={item.to}
      notification={item.notification}
      index={index}
      setActiveState={setActiveState}
      activeState={activeState}
    >
      {item.label}
    </PatientsViewButton>
  ))

  return (
    <div style={{ paddingBottom: '3rem' }}>
      <Blur></Blur>
      <StyledPatientsViewNavigationComponent>
        <SlidingBackground
          pos={activeState.pos}
          prevpos={activeState.prevpos}
        />
        {pagesToRender}
      </StyledPatientsViewNavigationComponent>
    </div>
  )
}
