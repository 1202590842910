import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { PatientsViewNavigation } from '../../components/PatientsViewNavigation/PatientsViewNavigation'
import { usePatientContext } from '../../core/contexts/patient-context'

const Content = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export function PatientDetails() {
  const { t } = useTranslation()
  const { setPatient } = usePatientContext()

  const patientNavPages = [
    {
      label: `${t('Detailed History')}`,
      to: 'detailed_history',
      notification: false
    },
    {
      label: `${t('Compact History')}`,
      to: 'compact_history',
      notification: false
    },
    {
      label: `${t('Current Period')}`,
      to: 'current_period',
      notification: false
    },
    { label: `${t('24 hours')}`, to: '24_hours', notification: false }
  ]

  useEffect(() => {
    return () => setPatient(null)
  }, [setPatient])

  return (
    <>
      <PatientsViewNavigation pages={patientNavPages}></PatientsViewNavigation>
      <Content>
        <Outlet />
      </Content>
    </>
  )
}
