import axios, { AxiosInstance } from 'axios'

export class AxiosRequest {
  private static accessToken: string

  static setAccessToken(token: string) {
    this.accessToken = token
  }

  static create(config: object = {}): AxiosInstance {
    return axios.create({
      ...config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      }
    })
  }
}
