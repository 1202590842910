import { faUserMd } from '@fortawesome/pro-solid-svg-icons'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { FlexColumnWrapper } from '../FlexWrappers/FlexColumnWrapper'
import { FlexRowWrapper } from '../FlexWrappers/FlexRowWrapper'
import { HeaderButton } from '../HeaderButton/HeaderButton'
import { Modal } from '../Modal/Modal'
import { Title, titleEnum } from '../Title/Title'
import { UserModal } from '../UserModal/UserModal'

const HeaderComponent = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 0.0625rem solid var(--border-color);
  backdrop-filter: blur(0.75rem);
  background-color: var(--white-color);
  padding: 0.25rem 2rem;
  height: 5.5625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`

const RelativeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const TitleAndPatientSearchWrapper = styled(FlexRowWrapper)`
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const DropdownContainer = styled(FlexColumnWrapper)`
  justify-content: center;
  align-items: center;
  position: relative;
`

export interface ClinicDataProps {
  clinicId: string
  hospitalName: string
}
type HeaderProps = {
  user: string | null
  logout: any
  leftContent?: ReactNode
  mainContent?: ReactNode
  rightContent?: ReactNode
}

export const Header = ({
  user,
  logout,
  leftContent,
  mainContent,
  rightContent
}: HeaderProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <HeaderComponent data-testid="mainHeader">
      <RelativeWrapper>
        <TitleAndPatientSearchWrapper>
          <Title size={titleEnum.small}></Title>
          {leftContent}
        </TitleAndPatientSearchWrapper>
        {mainContent}
        <FlexRowWrapper>
          {rightContent}
          <DropdownContainer>
            {user && (
              <HeaderButton
                data-testid="logoutButton"
                icon={faUserMd}
                onClick={handleDropdown}
              >
                {user}
              </HeaderButton>
            )}
          </DropdownContainer>
        </FlexRowWrapper>
        {user && showDropdown && (
          <Modal isOpen={showDropdown}>
            <UserModal
              handleDropdown={handleDropdown}
              username={user}
              onLogout={logout}
            ></UserModal>
          </Modal>
        )}
      </RelativeWrapper>
    </HeaderComponent>
  )
}
