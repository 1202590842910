import Keycloak from 'keycloak-js'

export const keycloak = new Keycloak({
  url: '/auth/',
  realm: 'measurelet',
  clientId: 'portal'
})

export const initOptions = {
  checkLoginIframe: false,
  onLoad: 'check-sso',
  redirectUri: `${window.location.origin}/portal`
}
