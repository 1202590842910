import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import PatientContext from '../../core/contexts/patient-context'
import { Patient } from '../../core/entities/patient.entity'
import { useSession } from '../../core/session/use-session'
import { ClinicCard } from '../ClinicCard/ClinicCard'
import { Header } from '../Header/Header'
import { PatientSearch } from '../PatientSearch/PatientSearch'

const MainContent = styled.main`
  margin: 6rem 2rem 0;
  width: 100%;
  background-color: var(--transparentWhite80-color);
`

export const PortalLayout = () => {
  const { t } = useTranslation()
  const { logout, user, userError } = useSession()
  const [patient, setPatient] = useState<Patient | null>(null)
  if (userError) {
    return <>{t('There was an error fetching the user.')}</>
  }
  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {renderHeader()}
      <MainContent>
        <Outlet />
      </MainContent>
    </PatientContext.Provider>
  )

  /**
   * Returns the appropriate header depending on the type of the user
   *
   * @returns Header component depending on the user type
   */
  function renderHeader() {
    return (
      <Header
        user={user ? user.name : ''}
        logout={logout}
        leftContent={patient ? <PatientSearch /> : null}
        mainContent={patient ? <ClinicCard CPR={patient.mrn} /> : null}
      />
    )
  }
}
