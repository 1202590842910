import { createContext, useContext } from 'react'
import { Patient } from '../entities/patient.entity'

type PatientContextState = {
  patient: Patient | null
  setPatient: (patient: Patient | null) => void
}

const PatientContext = createContext<PatientContextState>({
  patient: null,
  setPatient: () => null
})

export function usePatientContext() {
  return useContext(PatientContext)
}

export default PatientContext
