import styled from 'styled-components'
import { CombinedTables } from '../../components/CombinedTables/CombinedTables'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePatientData24 } from '../../hooks/get-patient-data-24'

const Wrapper = styled.div`
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const OneDay = () => {
  const { patient } = usePatientContext()
  const { patientViewData } = usePatientData24(patient)
  if (patientViewData) {
    return (
      <Wrapper>
        <CombinedTables
          data={patientViewData.balance}
          is24={true}
          id={'24hourday'}
        />
      </Wrapper>
    )
  } else {
    return <Spinner testid="24hourloading" spinnersize={spinnerSize.large} />
  }
}
