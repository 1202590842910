import styled from 'styled-components'
import { Spinner, spinnerSize } from '../Spinner/Spinner'
import dayjs from '../../core/dayjs/dayjs'
import { DailyTableDay } from '../DailyTable/DailyTable'
import { dotanimation } from '../CombinedTables/CombinedTables'
import { anchorClick } from '../../containers/Detailed/Detailed'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

export interface InputBalance {
  sumOfIV: number
  countOfIV: number
  countOfOral: number
  sumOfOral: number
  countOfDiscard: number
  sumOfDiscard: number
}

export interface OutputBalance {
  countOfUrine: number
  sumOfUrine: number
  countOfFecal: number
  sumOfFecal: number
  countOfOther: number
  sumOfOther: number
}

export interface DailyBalanceEvent {
  name: string
  day: string
  events: Array<DailyTableDay>
  balance: number
  inputTotal: string
  outputTotal: string
  currentDay: boolean
  daysToNextDay: number
  startTime: string
  endTime: string
  inputBalance: InputBalance
  outputBalance: OutputBalance
  inputCount: number
  outputCount: number
  lastDay: boolean
}

export interface DailyBalanceDataId {
  data: DailyBalanceEvent
  is24: boolean
  id: string
}
export interface DailyBalanceData {
  data: DailyBalanceEvent
  is24: boolean
  setShowTooltip?: Dispatch<SetStateAction<boolean>>
}
export interface DailyBalanceObject {
  balances: Array<DailyBalanceEvent>
  type: string
  endTime: string | null
  startTime: string | null
  totalBalance?: number | null
}
export interface DailyBalance24Object {
  balance: DailyBalanceEvent
  type: string
}

export interface CompactTableProps {
  data: DailyBalanceObject | null
}

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3rem;
`
const ScrollTable = styled.div`
  height: 45.875rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--border-color);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome */
  }
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--darkfont-color);
  position: relative;
`
const Box = styled.div`
  height: 2.9375rem;
  width: 13.6785rem;
  display: flex;
  border-bottom: 0.0625rem solid var(--border-color);
  justify-content: inherit;
`
const DateBox = styled(Box)`
  width: 10.6875rem;
  position: relative;
`
const BalanceBox = styled(DateBox)`
  justify-content: flex-end;
  width: 8.8125rem;
`
const BoxValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  gap: 0.5rem;
`
const BalanceValues = styled(BoxValues)`
  margin-left: 0rem;
  margin-right: 1rem;
`
const Value = styled.div``
const Unit = styled.div`
  color: var(--greyFont-color);
`
const HeaderBox = styled.div`
  height: 2.9375rem;
  width: 13.6785rem;
  display: flex;
  background-color: var(--headerbackground-color);
  align-items: center;
  border-bottom: 0.0625rem solid var(--border-color);
  white-space: nowrap;
  justify-content: inherit;
`
const DateHeaderBox = styled(HeaderBox)`
  width: 10.6875rem;
`
const BalanceHeaderBox = styled(DateHeaderBox)`
  justify-content: flex-end;
  width: 8.8125rem;
`
const TodayIndicator = styled.div`
  width: 0.375rem;
  height: calc(100% + 0.0625rem);
  background-color: var(--brand-orange-color);
  position: absolute;
  left: 0;
  animation-name: ${dotanimation};
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`
const MissingDataComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-bg-color);
  border-bottom: 0.0625rem solid var(--border-color);
  padding: 1.25rem;
  cursor: default;
`
const WrapperRow = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const MissingDataText = styled.div`
  color: var(--greyFont-color);
  font-size: 0.875rem;
  font-family: interMedium;
  font-weight: 600;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const HeaderRow = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 1;
`
const MissingData = (days: number) => {
  const { t } = useTranslation()
  return (
    <MissingDataComponent>
      <MissingDataText>{t('No data')}</MissingDataText>
      <MissingDataText>
        {days} {t('days')}
      </MissingDataText>
    </MissingDataComponent>
  )
}

const TableComponent = ({ data }: CompactTableProps) => {
  const { t } = useTranslation()
  if (data) {
    const allRows = data.balances.map((item, index) => {
      return (
        <WrapperRow key={item.day}>
          <Row
            data-testid={'compactrow' + index}
            onClick={() => anchorClick(index.toString())}
          >
            <DateBox>
              {item.currentDay ? <TodayIndicator /> : <></>}
              <BoxValues>
                <Value>{dayjs(item.day).format('DD/MM/YYYY')}</Value>
              </BoxValues>
            </DateBox>
            <Box>
              <BoxValues>
                <Value>{`${item.inputCount} ( ${item.inputTotal} g)`}</Value>
              </BoxValues>
            </Box>
            <Box>
              <BoxValues>
                <Value>{`${item.outputCount} (${
                  parseInt(item.outputTotal) === 0
                    ? '0'
                    : `- ${item.outputTotal}`
                } g)`}</Value>
              </BoxValues>
            </Box>
            <BalanceBox>
              <BalanceValues>
                <Value>
                  {item.balance === 0
                    ? item.balance
                    : item.balance < 0
                    ? '-\xa0' + Math.abs(item.balance)
                    : '+\xa0' + item.balance}
                </Value>
                <Unit>g</Unit>
              </BalanceValues>
            </BalanceBox>
          </Row>
          {item.daysToNextDay > 0 &&
          item.lastDay &&
          data.type === 'dailybalance' ? (
            MissingData(Math.abs(item.daysToNextDay))
          ) : (
            <></>
          )}
        </WrapperRow>
      )
    })
    return (
      <Table data-testid="HistoryTable">
        <HeaderRow>
          <DateHeaderBox>
            <BoxValues>{t('Date')}</BoxValues>
          </DateHeaderBox>
          <HeaderBox>
            <BoxValues>{t('Input samples (total weight)')}</BoxValues>
          </HeaderBox>
          <HeaderBox>
            <BoxValues>{t('Output samples (total weight)')}</BoxValues>
          </HeaderBox>
          <BalanceHeaderBox>
            <BalanceValues>{t('Fluid Balance')}</BalanceValues>
          </BalanceHeaderBox>
        </HeaderRow>
        {allRows}
      </Table>
    )
  } else {
    return (
      <Spinner testid="compactTableLoading" spinnersize={spinnerSize.large} />
    )
  }
}

export const CompactTable = ({ data }: CompactTableProps) => {
  return (
    <TableWrapper data-testid="compacttable">
      <ScrollTable>
        <TableComponent key={'compactTable'} data={data} />
      </ScrollTable>
    </TableWrapper>
  )
}
