import { ReactNode, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { SearchButton } from '../SearchButton/SearchButton'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { Spinner, spinnerSize } from '../Spinner/Spinner'

interface CssProps {
  focusState: boolean | null
}

const SearchBarComponent = styled.form<CssProps>`
  border: 1px solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.4375rem;
  position: relative;
  padding: 0.125rem;
  border-color: ${(props) =>
    props.focusState
      ? 'var(--darkFont-color)'
      : 'var(--searchBarNotFocused-color)'};
  box-shadow: ${(props) =>
    props.focusState
      ? '0rem 0rem 0rem 0.375rem var(--searchBarFocused-color)'
      : 'var(--searchBarNotFocused-color)'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`
const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--darkFont-color);
  margin-left: 1.25rem;
`
const DeleteLogo = styled(FontAwesomeIcon)<CssProps>`
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--deleteButton-color);
  cursor: ${(props) => (props.focusState ? 'pointer' : 'default')};
  &:hover {
    color: var(--darkFont-color);
  }
`
const moveLabel = keyframes`
from {
  bottom:11.5%;
  left:20%;
}
to {
  bottom:250%;
  left:0%;
}
`
const moveLabelBack = keyframes`
from {
  bottom:250%;
  left:0%;
}
to {
  bottom:11.5%;
  left:20%;
}
`

const SearchBarLabel = styled.div<CssProps>`
  font-family: interMedium;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--darkFont-color);
  position: absolute;
  pointer-events: none;
  line-height: 1.5rem;
  bottom: 11.5%;
  left: 20%;
  &.move {
    animation: ${moveLabel} 0.3s forwards 1;
  }
  &.moveBack {
    animation: ${moveLabelBack} 0.3s forwards 1;
  }
`
const InputWrapper = styled.div`
  gap: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const DeleteButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  &:disabled {
    opacity: 0;
  }
`

const SearchBarInput = styled.input`
  color: var(--darkFont-color);
  font-family: interMedium;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.1875rem;
  border: none;
  width: 12.25rem;
  &:focus {
    outline: none;
  }
`

const ErrorContainer = styled.div`
  position: absolute;
  color: var(--white-color);
  padding: 1.75rem;
  background: red;
  border-radius: 0.875rem;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
`

export type FormValues = {
  CPR: string
}

type SearchBarProps = {
  children: ReactNode
  onSuccess: (data: FormValues) => void
  patientError: boolean
  setPatientError: (value: boolean) => void
  searching: boolean
}

export const SearchBar = ({
  children,
  onSuccess,
  patientError,
  setPatientError,
  searching
}: SearchBarProps) => {
  const {
    register,
    getValues,
    resetField,
    formState: { isDirty },
    setValue,
    handleSubmit
  } = useForm<FormValues>({ mode: 'onChange', defaultValues: { CPR: '' } })
  const onSubmit: SubmitHandler<FormValues> = (data) => onSuccess(data)
  const [focusState, setFocusState] = useState<boolean | null>(null)
  const [currentCPR, setCurrentCPR] = useState('')
  const { t } = useTranslation()
  function handleOnFocus() {
    setFocusState(true)
  }
  function handleOnBlur() {
    setFocusState(isDirty)
  }

  function clearField() {
    resetField('CPR')
    setPatientError(false)
  }

  function IdFunction() {
    const value = getValues('CPR')
    if (value.length > 10) {
      setValue('CPR', currentCPR)
    } else {
      setCurrentCPR(value)
    }
  }
  return (
    <SearchBarComponent
      focusState={focusState}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="searchBar"
    >
      <InputWrapper>
        <SearchBarLabel
          className={
            focusState !== null
              ? focusState === true
                ? 'move'
                : 'moveBack'
              : ''
          }
          focusState={focusState}
          data-testid="SearchBarLabel"
        >
          {t('Enter patient ID')}
        </SearchBarLabel>
        <SearchLogo icon={faSearch}></SearchLogo>
        <SearchBarInput
          {...register('CPR', {
            onChange: () => IdFunction(),
            required: 'Please enter patient ID',
            maxLength: 10
          })}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          autoComplete="off"
          data-testid="SearchBarInput"
        ></SearchBarInput>

        <DeleteButtonWrapper
          type="reset"
          disabled={!isDirty}
          onClick={clearField}
          data-testid="clearFieldButton"
        >
          {searching ? (
            <Spinner
              testid="searchingForPatientSpinner"
              spinnersize={spinnerSize.medium}
            ></Spinner>
          ) : (
            <DeleteLogo focusState={isDirty} icon={faTimesCircle} />
          )}
        </DeleteButtonWrapper>
      </InputWrapper>
      <SearchButton
        type="submit"
        disabled={getValues('CPR').length !== 10}
        data-testid="submitButton"
      >
        {children}
      </SearchButton>
      {patientError && (
        <ErrorContainer>{t('Error - Patient not found')}</ErrorContainer>
      )}
    </SearchBarComponent>
  )
}
