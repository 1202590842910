import styled from 'styled-components'

export const SearchButton = styled.button`
  background-color: var(--darkFont-color);
  color: var(--white-color);
  font-family: interMedium;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 0.75rem;
  border: none;
  padding: 1.125rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 9rem;
  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    background-color: var(--searchButtonDisabled-color);
  }
`
