import { useKeycloak } from '@react-keycloak/web'
import { KeycloakLogoutOptions } from 'keycloak-js'
import { useEffect, useState } from 'react'
import { getCurrentUser } from '../api/get-current-user-api'
import { User } from '../entities/user.entity'

export function useSession() {
  const { keycloak } = useKeycloak()
  const [user, setUser] = useState<User | null>(null)
  const [userError, setUserError] = useState(false)
  const isAuthenticated = keycloak?.authenticated || false

  useEffect(() => {
    if (isAuthenticated) {
      const controller = new AbortController()

      getCurrentUser({ signal: controller.signal })
        .then(setUser)
        .catch(setUserError)

      return () => controller.abort()
    }
  }, [isAuthenticated])

  return {
    isAuthenticated,
    user,
    userError,
    login: async () => {
      await keycloak.login()
    },
    logout: async (options?: KeycloakLogoutOptions) => {
      await keycloak.logout(options)
    }
  }
}
