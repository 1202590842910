import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PatientSearchComponent = styled.button`
  color: var(--darkFont-color);
  background-color: var(--patientSearchBG-color);
  font-family: interMedium;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border: none;
  gap: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--patientSearchBG-color);
    color: var(--darkFont-color);
  }
`

const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 400;
`

export const PatientSearch = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <PatientSearchComponent
      data-testid="patientSearchButton"
      onClick={() => navigate('patients/search')}
    >
      <SearchLogo icon={faSearch}></SearchLogo>
      {t('Patient Search')}
    </PatientSearchComponent>
  )
}
