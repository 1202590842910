import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import logo from '../../measureletLogo.png'
export enum titleEnum {
  small,
  medium,
  large
}
interface TitleProps {
  size: titleEnum
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img<TitleProps>`
  width: ${(props) =>
    props.size === titleEnum.small ? '15.5rem' : '18.0625rem'};
  height: ${(props) =>
    props.size === titleEnum.small ? '2.5rem' : '3.125rem'};
`
export const Title = ({ size }: TitleProps) => {
  const navigate = useNavigate()

  return (
    <Wrapper
      onClick={
        size === titleEnum.small
          ? () => navigate('patients/search')
          : () => null
      }
      data-testid="headerTitle"
    >
      <Logo size={size} alt="measureletlogo" src={logo} />
    </Wrapper>
  )
}
