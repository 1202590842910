import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'

export enum spinnerSize {
  small,
  medium,
  large
}
interface SpinnerProps {
  spinnersize: spinnerSize
  testid?: string
}

export const iconStyle = {
  '--fa-primary-color': 'var(--darkFont-color)',
  '--fa-secondary-color': 'var(--spinnerBG-color)'
}
const GetSpinnerSize = (size: spinnerSize) => {
  switch (size) {
    case spinnerSize.small:
      return '1rem'
    case spinnerSize.medium:
      return '1.5rem'
    default:
      return '2rem'
  }
}
const SpinnerIcon = styled(FontAwesomeIcon)<SpinnerProps>`
  font-size: ${(props) => GetSpinnerSize(props.spinnersize)};
`

export const Spinner = ({ spinnersize, testid }: SpinnerProps) => {
  return (
    <SpinnerIcon
      data-testid={testid}
      icon={faSpinnerThird}
      spinnersize={spinnersize}
      className="fa-spin"
      style={iconStyle as CSSProperties}
    ></SpinnerIcon>
  )
}
