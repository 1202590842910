import { AxiosRequest } from './axios-request'
import config from '../config'

export function getPatientView24(patientId: string, signal: AbortSignal) {
  return AxiosRequest.create().get(
    `${config.API_URL}/patients/${patientId}/view`,
    {
      params: { type: 'dailybalance24' },
      signal
    }
  )
}
