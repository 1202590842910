import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DetailedTitle,
  TitleType
} from '../../components/CombinedTables/CombinedTables'
import { CompactTable } from '../../components/CompactTable/CompactTable'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { usePatientContext } from '../../core/contexts/patient-context'
import dayjs from '../../core/dayjs/dayjs'
import { usePatientData } from '../../hooks/get-patient-data'
import { GetDaysString } from '../../helpers/getDaysString'
import { GetHoursString } from '../../helpers/getHoursString'
import { GetMinutesString } from '../../helpers/getMinutesString'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 1rem;
`
const TitleContainer = styled.div`
  padding: 1rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.5rem;
  width: 45rem;
`
export function CurrentPeriod() {
  const { patient } = usePatientContext()
  const { patientViewData } = usePatientData(patient, 'currentperiod')
  const { t } = useTranslation()

  const formatTimeAgo = (time: number) => {
    const days = Math.trunc(time / 1440)
    const hours = Math.trunc((time - days * 1440) / 60)
    const minutes = time - days * 1440 - hours * 60
    return `${days} ${t(GetDaysString(days))} ${hours} ${t(
      GetHoursString(hours)
    )} ${minutes} ${t(GetMinutesString(minutes))}`
  }

  if (patientViewData) {
    const startTime =
      patientViewData.startTime !== null
        ? dayjs(patientViewData.startTime)
        : null
    const formattedTime = formatTimeAgo(
      dayjs(new Date()).diff(startTime, 'minute')
    )
    return (
      <Container data-testid="CurrentPeriodPage">
        <TitleContainer>
          <DetailedTitle
            endtime={
              startTime !== null
                ? `${dayjs(new Date()).format('HH:mm')} ${t(
                    'today'
                  )} (${formattedTime})`
                : t('No Active Period')
            }
            starttime={
              startTime !== null
                ? `${startTime.format('DD/MM/YYYY')} ${startTime.format(
                    'HH:mm'
                  )}`
                : ''
            }
            date={''}
            balance={patientViewData.totalBalance ?? null}
            type={TitleType.iscurrentperiod}
          />
        </TitleContainer>
        <CompactTable data={patientViewData} />
      </Container>
    )
  } else {
    return <Spinner spinnersize={spinnerSize.large} />
  }
}
