import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormValues, SearchBar } from '../../components/SearchBar/SearchBar'
import { getPatient } from '../../core/api/get-patient'
import { usePatientContext } from '../../core/contexts/patient-context'

export function PatientSearch() {
  const { t } = useTranslation()
  const [patientError, setPatientError] = useState(false)
  const [searchingState, setSearchingState] = useState(false)
  const navigate = useNavigate()
  const { setPatient } = usePatientContext()

  const handleSuccess = (formdata: FormValues) => {
    setSearchingState(true)
    getPatient(formdata.CPR.replace(/-/g, ''))
      .then((response) => {
        const patient = response.data.pop()
        if (!patient) {
          throw new Error(t('Patient not found'))
        }
        setPatient(patient)
        navigate(`/portal/patients/${patient.patientId}/detailed_history`)
      })
      .catch(function (error) {
        console.log(error)
        setPatientError(true)
        setSearchingState(false)
      })
  }

  return (
    <SearchBar
      onSuccess={handleSuccess}
      patientError={patientError}
      setPatientError={setPatientError}
      searching={searchingState}
    >
      {searchingState ? `${t('Searching')}` : `${t('Search')}`}
    </SearchBar>
  )
}
