import { AxiosRequest } from './axios-request'
import config from '../config'

export function getPatientView(
  patientId: string,
  type: string,
  signal: AbortSignal
) {
  return AxiosRequest.create().get(
    `${config.API_URL}/patients/${patientId}/view`,
    {
      params: { type },
      signal
    }
  )
}
